













import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: ['text', 'button', 'isWarning'],
})
export default class Popup extends Vue {
  confirm() {
    this.$emit('confirm');
  }
  cancel() {
    this.$emit('cancel');
  }
}
