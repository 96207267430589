















































import { Component, Vue } from 'vue-property-decorator';
import { generalDataService } from '@/services/GeneralDataService';
import Popup from '@/components/common/Popup.vue';
import EventBus from '@/EventBus';
import LoadingBar from '@/components/common/LoadingBar.vue';
import ErrorBar from '@/components/common/ErrorBar.vue';

@Component({
  components: { ErrorBar, LoadingBar, Popup },
  props: ['options', 'data', 'path', 'apiPath', 'isLoading', 'hasError', 'deepPath', 'deepPathName'],
})
export default class DataTable extends Vue {
  removeId = -1;
  isPopup = false;
  goTo(id: any) {
    this.$router.push(`/${this.$props.path}/${id}`).catch(() => {});
    this.$emit('edit-item', id);
  }
  moveTo(id: any) {
    this.$router.push(`/${this.$props.deepPath}/${id}`).catch(() => {});
  }
  cancelPopup() {
    this.isPopup = false;
  }
  confirmRemove() {
    this.isPopup = false;
    generalDataService
      .delete(this.removeId, this.$props.apiPath)
      .then(() => {
        this.$emit('update-list');
        EventBus.$emit('show-info', 'Pozycja została usunięta');
      })
      .catch(() => {});
  }
  remove(id: any) {
    this.removeId = id;
    this.isPopup = true;
  }
  getFieldOptions(item: any, field: any) {
    return item[field.field] !== undefined && item[field.field] !== null
      ? item[field.field]
          .toString()
          .split(',')
          .map((el: any) => field.options[el] || '?')
      : '?';
  }
}
