










import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: ['hasError', 'text'],
})
export default class ErrorBar extends Vue {}
