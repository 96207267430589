import { httpService } from '@/services/HttpService';

class GeneralDataService {
  delete(id: number, path: string): Promise<any> {
    return httpService.delete(`${path}/${id}`, {}, {});
  }
  update(id: number, path: string, fields: any): Promise<any> {
    return httpService.put(`${path}/${id}`, fields, {});
  }
  add(path: string, fields: any): Promise<any> {
    return httpService.post(path, fields, {});
  }
  list(path: string): Promise<any> {
    return httpService.get(path, {}, {});
  }
  getItem(id: number | string, path: string): Promise<any> {
    return httpService.get(`${path}/${id}`, {}, {});
  }
}

export const generalDataService = new GeneralDataService();
